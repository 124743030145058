import {
  FreightTrackingInfoBaseData,
  FreightTrackingInfoSaveData,
} from "@deliverr/commons-clients/lib/transportation/legacy-copy-pasta";
import { CustomsInformation } from "@deliverr/commons-clients/lib/product";
import {
  InboundFreightPalletSetup,
  InboundFreightPickupFE,
  InboundPackageData,
  InboundRateQuoteFE,
  ShippingPlan,
  ShippingPlanItemLotExpirationRaw,
} from "@deliverr/legacy-inbound-client";
import {
  ProductPrepCategory,
  ProductPrepPackagingType,
} from "@deliverr/commons-clients/lib/product/ProductPreparation";
import { InboundShipmentStatus } from "common/clients/inbound/InboundShipment/InboundShipmentStatus";

import { ShippingMethod } from "inbounds/ShippingMethod";
import BoxArrangement from "inbounds/steps/ship/BoxArrangement";
import { Mutable } from "utility-types";
import { Product } from "@deliverr/commons-clients/lib/product/Product";
import { FlexportShipmentStatus } from "./ShipmentDetails/FlexportShipmentStatus";
import { CargoType } from "./steps/ship/freight/FreightContainerDetailsStep/types";
import BarcodeFormat from "common/BarcodeFormat";

export interface ShippingPlanRenameParams {
  name: string;
  sellerId: string;
  shippingPlanId?: number;
}

export interface BoxSize {
  width: number;
  length: number;
  height: number;
}

export interface InboundSearchResult {
  shippingPlanId: number;
  shipmentId: number;
  shipmentIndex: number;
  shippingPlanName: string;
  shippingPlanStatus: string;
  shippingPlanCreatedAtUnix: number;
  shipmentStatus: InboundShipmentStatus;
  warehouseCity: string;
  warehouseState: string;
  originAddressCity?: string;
  originAddressState?: string;
  originAddressCountry?: string;
  originName?: string;
  destinationAddressCity?: string;
  destinationAddressState?: string;
  destinationAddressCountry?: string;
  destinationAddressName?: string;
  shippingPlanQty: number;
  shipmentQty: number;
  shippingPlanSkuCount: number;
  shipmentSkuCount: number;
  isForwarding: boolean;
  asnIds: number[];
  cdskus: string[];
  crossDockWarehouseId?: string;
  crossDockWarehouseCity?: string;
  crossDockWarehouseState?: string;
  asnId?: number;
  sellerId?: string;
  isPromotional?: boolean;
  shipmentExpectedQty: number;
  shipmentReceivedQty: number;
  shipmentDamagedQty: number;
  shipmentDskuQty: number;
  shipmentNCProductQty: number;
  shipmentCompletedWithDiscrepancy: boolean;
  shipmentOverReceivedQty: number;
  shipmentUnderReceivedQty: number;
  inboundSource?: string;
  bookingType?: string;
  bookingSubmitted?: boolean;
  bookingId?: string;
  bookingStatus?: FlexportShipmentStatus;
  /** Deliverr Prep ID */
  dpId?: string;
  carrierEmail?: string;
  shippingOption?: string;
}

export interface ProductBarcode {
  barcode: string;
  format: BarcodeFormat;
}

export interface DraftShippingPlanItem extends ShippingPlanItemLotExpirationRaw {
  dsku: string;
  numberOfCases: number;
  caseQty: number;
  qty: number;
  barcode?: string;
}

export interface DraftShippingPlan extends Mutable<Partial<ShippingPlan>> {
  id: number;
  name: string;
  isPromotional?: boolean;
}

export interface FreightShipmentInfoFlags {
  hasConfirmedPalletCompliance?: boolean; // true if the seller has checked the box for "Freight Instructions"
  hasConfirmedAppointment?: boolean; // true if the seller has checked the box for pick-up appointment scheduling
}

export type FreightShipmentBaseInfo = Omit<FreightTrackingInfoSaveData, keyof FreightTrackingInfoBaseData>;

export interface FreightShipmentInfoWithoutSaved extends FreightShipmentBaseInfo, FreightShipmentInfoFlags {}

export interface FreightShipmentInfo extends FreightShipmentInfoWithoutSaved {
  savedInfo?: FreightShipmentBaseInfo;
  hasReceivingInfoChanged?: boolean;
  hasShipmentConfirmationInfoChanged?: boolean;
}

export type RateQuote = Pick<
  InboundRateQuoteFE,
  "id" | "rateShoppingMethod" | "shippingProvider" | "externalEta" | "amount" | "fullAmount" | "shippingOption"
>;

export interface InboundFreightPickup extends Omit<InboundFreightPickupFE, "pickupDate"> {
  pickupDate?: Date;
}

export interface DeliverrLtlShipment {
  billOfLading: string;
  hasConfirmedPallets: boolean;
  hasConfirmedPickupInfo: boolean;
  hasConfirmedShipperInfo: boolean;
  hasConfirmedRateQuote: boolean;
  hasConfirmedReviewShipment: boolean;
  hasConfirmedShipmentSummary: boolean;
  hasDownloadedBol: boolean;
  numberOfPallets: number;
  totalCargoWeight: number;
  palletConfigurations: InboundFreightPalletSetup[];
  pickupInfo: InboundFreightPickup;
  quotes: RateQuote[];
  selectedQuoteId: number;
}

export interface PlannedShipment {
  id: number;
  cargoType: CargoType;
  shippingMethod?: ShippingMethod;
  boxArrangement: BoxArrangement;
  packages: ReadonlyArray<InboundPackageData>;
  packageCount: number;
  identicalPackageCounts: ReadonlyArray<number>;
  boxSizes: BoxSize[];
  selectedBoxSizes: ReadonlyArray<number>;
  boxConfirmAttempted: boolean;
  boxesConfirmed: boolean;
  boxSaveConfigurationAttempted: boolean;
  hasChargesAccepted: boolean;
  hasConfirmedPricingAndFees?: boolean;
  isValid: boolean;
  estimatedRates?: RateQuote[];
  estimatedRatesUpdatedAt?: number;
  hasDownloadedBoxLabels?: boolean;
  hasConfirmedBatteriesCompliance?: boolean;
  hasDownloadedPackingList?: boolean;
}

// determines order of steps in the seller inbound flow
export enum InboundStep {
  SELECT_INBOUND_TYPE = "SELECT_INBOUND_TYPE",
  SELECT_PRODUCTS = "SELECT_PRODUCTS",
  SHIPPING_PLAN_PRODUCTS = "SHIPPING_PLAN_PRODUCTS",
  ASSIGN_CATEGORIES = "ASSIGN_CATEGORIES",
  DELIVERR_PREP = "DELIVERR_PREP",
  LOT_INFORMATION = "LOT_INFORMATION",
  PACKAGING_REQUIREMENTS = "PACKAGING_REQUIREMENTS",
  CUSTOMS = "CUSTOMS",
  BARCODE_INPUT = "BARCODE_INPUT",
  BARCODE_PRINT = "BARCODE_PRINT",
  FROM_ADDRESS = "FROM_ADDRESS",
  DISTRIBUTE_SELECT = "DISTRIBUTE_SELECT",
  ADD_DIMENSIONS = "ADD_DIMENSIONS",
  SHIP = "SHIP",
  /**
   * This is not a typical Inbound Step -- it is only invoked for certain flows (i.e. ones that require LTL Confirmations),
   * and is done via an explicit call in InboundActions.confirmShipment.
   **/
  SHIPMENT_CONFIRMED = "SHIPMENT_CONFIRMED",
}

export interface ShippingPlanItemDetail {
  name: string;
  dsku: string;
  msku: string;
  qty: number;
  category?: ProductPrepCategory;
  packagingType?: ProductPrepPackagingType;
  customsInformation?: CustomsInformation;
  brandedPackaging?: Product["brandedPackaging"];
}

export interface ShipmentItemDetail extends ShippingPlanItemDetail {
  shipmentId: number;
}
