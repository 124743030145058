export const TEXT_ATTRIBUTES: string[] = [
  "shippingPlanName",
  "shipmentStatus",
  "shippingPlanStatus",
  "warehouseCity",
  "warehouseState",
  "shippingPlanMskus",
  "shippingPlanDskus",
  "cdskus",
  "barcodes",
  "dpId",
  "asnId",
  "asnIds",
];
export const NUMERIC_ATTRIBUTES: string[] = ["shippingPlanId", "shipmentId"];
export const IGNORE_ARCHIVED = "-ARCHIVED ";
export const ARCHIVED = "ARCHIVED";
export const BOOLEAN_ATTRIBUTES: string[] = ["isActive", "shipmentCompletedWithDiscrepancy"];
export const LTL_EXTERNAL_WITH_NO_CARRIER_EMAIL = "LTL_EXTERNAL_WITH_NO_CARRIER_EMAIL";
