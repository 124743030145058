import { PackagingType } from "@deliverr/commons-objects";
import React from "react";
import { FormattedMessage } from "react-intl";
import { LabelText, PackagingSelectButton, StyledModal } from "./style";
import sharedCls from "../InventoryDetail.less";
import { Button, DefaultTheme, defaultTheme, Grid, Icon, RadioTileGroup, Stack, Title } from "common/components/ui";
import { packagingSelectModalId, usePackagingSelect } from "./usePackagingSelect";
import { Product } from "@deliverr/business-types";
import styled from "@emotion/styled";

const polybagTitle = (
  <FormattedMessage id={"InventoryDetail.FulfillmentDetails.PolyTitle"} defaultMessage={"Flexport Recommended"} />
);

const TextMessage = styled.span<{}, DefaultTheme>(
  ({ theme }) => `
    color: ${theme.colors.BLUE["300"]};
    font-weight: ${theme.font.weight.BOLD};
    cursor: pointer;
  `
);

export const packagingTitle: Record<PackagingType, JSX.Element> = {
  [PackagingType.POLY_BAG]: polybagTitle,
  [PackagingType.BUBBLE_MAILER]: polybagTitle,
  [PackagingType.BOX]: (
    <FormattedMessage id={"InventoryDetail.FulfillmentDetails.BoxTitle"} defaultMessage={"Box Only"} />
  ),
  [PackagingType.SHIPS_IN_OWN_CONTAINER]: (
    <FormattedMessage id={"InventoryDetail.FulfillmentDetails.SCIOCTitle"} defaultMessage={"Ready to Ship"} />
  ),
};

const polybagDesc = (
  <FormattedMessage
    id={"InventoryDetail.FulfillmentDetails.PolyDesc"}
    defaultMessage={
      "Likely to ship in a polybag or bubble mailer if possible. Heavier products will likely ship in a box."
    }
  />
);

export const packagingDesc: Record<PackagingType, JSX.Element> = {
  [PackagingType.POLY_BAG]: polybagDesc,
  [PackagingType.BUBBLE_MAILER]: polybagDesc,
  [PackagingType.BOX]: (
    <FormattedMessage
      id={"InventoryDetail.FulfillmentDetails.BoxDesc"}
      defaultMessage={"Ship in a box. Recommended for fragile items."}
    />
  ),
  [PackagingType.SHIPS_IN_OWN_CONTAINER]: (
    <FormattedMessage
      id={"InventoryDetail.FulfillmentDetails.SCIOCDesc"}
      defaultMessage={"Ship in own container (SIOC) with no additional packaging."}
    />
  ),
};

const ExistingPackagingContainer = ({
  showModal,
  initialSelectedPackaging,
}: {
  showModal: () => void;
  initialSelectedPackaging: PackagingType;
}) => {
  return (
    <>
      <LabelText className={sharedCls.labelName}>
        <FormattedMessage id={"InventoryDetail.Packaging"} defaultMessage={"Packaging"} />
      </LabelText>
      <PackagingSelectButton onClick={showModal} data-testid="changePackagingModal_trigger">
        {packagingTitle[initialSelectedPackaging]}
        <Icon type={"pen"} color={defaultTheme.colors.BLUE[300]} />
      </PackagingSelectButton>
      <LabelText className={sharedCls.labelName}>{packagingDesc[initialSelectedPackaging]}</LabelText>
    </>
  );
};

export const PackagingSelect = ({
  product,
  hideExistingPackaging = false,
  packagingOptionsOverride,
  isUpdateFromInboundPage = false,
}: {
  product?: Product;
  hideExistingPackaging?: boolean;
  packagingOptionsOverride?: { label: JSX.Element; content: JSX.Element; value: PackagingType }[];
  isUpdateFromInboundPage?: boolean;
}) => {
  const {
    initialSelectedPackaging,
    showModal,
    packagingOptions,
    selectedPackaging,
    setSelectedPackaging,
    changePackaging,
    resetPackagingSelect,
    isChangePackagingButtonDisabled,
    isLoading,
  } = usePackagingSelect(product, isUpdateFromInboundPage);

  return (
    <>
      <StyledModal id={packagingSelectModalId} size={"md"} hasCloseButton={true}>
        <Stack gap="S5">
          <Title as="h2" displayAs="h3">
            <FormattedMessage id="InventoryDetail.PackagingSelectModal.title" defaultMessage="Change Packaging" />
          </Title>
          <p>
            <FormattedMessage
              id="InventoryDetail.PackagingSelectModal.desc"
              defaultMessage="Changing the shipping container will inform our fulfillment centers to follow these instructions and change this product’s fulfillment fee."
            />
          </p>
          <div>
            <RadioTileGroup
              currentValue={selectedPackaging}
              direction="VERTICAL"
              header="Change Packaging"
              name="changePackaging"
              onChange={(e) => setSelectedPackaging(e.target.value as typeof selectedPackaging)}
              block={true}
              options={packagingOptionsOverride ?? packagingOptions}
            />
          </div>
          <Grid columns="1fr 1fr" gap="S5" fullWidth>
            <Button secondary appearance="DEFAULT" block onClick={resetPackagingSelect}>
              <FormattedMessage id="cancel" defaultMessage={"Cancel"} />
            </Button>
            <Button
              appearance="DEFAULT"
              block
              onClick={changePackaging}
              data-testid="changePackagingModal_confirm"
              disabled={isChangePackagingButtonDisabled}
              loading={isLoading}
            >
              <FormattedMessage
                id="InventoryDetail.PackagingSelectModal.changePackaging"
                defaultMessage={"Change packaging"}
              />
            </Button>
          </Grid>
        </Stack>
      </StyledModal>
      {hideExistingPackaging ? (
        <TextMessage onClick={showModal}>Change packaging</TextMessage>
      ) : (
        <ExistingPackagingContainer showModal={showModal} initialSelectedPackaging={initialSelectedPackaging} />
      )}
    </>
  );
};
