import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { ManagedStep } from "common/components/StepContainer";
import { Box } from "common/components/ui";
import { Container } from "common/components/Container";
import { ReplenishmentOrderError } from "./ReplenishmentOrderError";
import { ReplenishmentProductErrorList } from "./ReplenishmentProductErrorList";
import { useReplenishmentOrderCreationError } from "./useReplenishmentOrderCreationError";
import styled from "@emotion/styled";
import { ReplenishmentOrderErrorMessages } from "./ReplenishmentErrorMessages";

const StyledContainer = styled(Container)(`
    width: 100%;
`);

export const ReplenishmentOrderCreationError = () => {
  const { formatMessage } = useIntl();
  const { productsWithErrors, orderErrors, onRemoveProduct, isNextLoading } =
    useReplenishmentOrderCreationError();

  return (
    <ManagedStep
      title={formatMessage(ReplenishmentOrderErrorMessages.CardTitle)}
      subtitle={
        <Box maxWidth="32rem">
          <FormattedMessage {...ReplenishmentOrderErrorMessages.CardDescription} />
        </Box>
      }
      hasBack={true}
      isLoading={isNextLoading}
    >
      <StyledContainer>
        <ReplenishmentOrderError orderErrors={orderErrors} />
        <ReplenishmentProductErrorList onRemoveProduct={onRemoveProduct} productsWithErrors={productsWithErrors} />
      </StyledContainer>
    </ManagedStep>
  );
};
