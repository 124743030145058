import { FormattedMessage, useIntl } from "react-intl";
import { Icon, defaultTheme, toast } from "common/components/ui";
import React, { useEffect, useState } from "react";
import { ShippingSpecification, ShippingSpecificationSourceType } from "@deliverr/commons-clients";
import { addLoader, clearLoader } from "common/components/WithLoader/LoadingActions";
import { getProductDetail, updateEstimatedCosts } from "inventory/detail/InventoryDetailActions";
import { hasHazmatFragileSpec, isOptionDisabled } from "./helpers/isOptionDisabled";
import { packagingDesc, packagingTitle } from "./PackagingSelect";
import { useDispatch, useSelector } from "react-redux";

import { InventoryLoaderId } from "inventory/InventoryLoaderId";
import { PackagingType } from "@deliverr/commons-objects";
import { RootState } from "RootReducer";
import { SelectedOptionContainer } from "./style";
import { TooltipWithIcon } from "common/components/icons/TooltipWithIcon";
import cls from "./FulfillmentDetails.less";
import log from "Logger";
import { mustBeDefined } from "common/utils/mustBeDefined";
import { productClient } from "Clients";
import { useAsyncFn } from "react-use";
import { useModal } from "common/hooks/useModal";
import { Product } from "@deliverr/business-types";
import { updateProductCache } from "inbounds/InboundActions";

export const packagingSelectModalId = "packagingSelect";
const packagingOptionsArr = [PackagingType.POLY_BAG, PackagingType.BOX, PackagingType.SHIPS_IN_OWN_CONTAINER];

export const getLabelContent = (
  option: PackagingType,
  selectedPackaging: PackagingType,
  sourceType: ShippingSpecificationSourceType
) => {
  if (option === PackagingType.BOX && hasHazmatFragileSpec(selectedPackaging, sourceType)) {
    return (
      <SelectedOptionContainer>
        {packagingTitle[PackagingType.BOX]}
        <TooltipWithIcon
          message={
            <FormattedMessage
              id="InventoryDetail.PackagingModal.FragileRecommended"
              defaultMessage={
                "Flexport recommends that your product ships in box only to avoid damages. To request a change please contact {supportEmail}."
              }
              values={{
                supportEmail: mustBeDefined(process.env.SUPPORT_EMAIL),
              }}
            />
          }
          placement="top"
          tooltipProps={{
            overlayClassName: cls.hoverIconContent,
          }}
        >
          <Icon type={"info-circle"} color={defaultTheme.colors.NEUTRAL[300]} />
        </TooltipWithIcon>
      </SelectedOptionContainer>
    );
  } else {
    return packagingTitle[option];
  }
};

export const usePackagingSelect = (productToUse?: Product, isUpdateFromInboundPage = false) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { hideModal, showModal } = useModal(packagingSelectModalId);

  const productInInventoryDetail = useSelector((state: RootState) => {
    if (state.inventoryDetail?.product) {
      return state.inventoryDetail.product;
    }
    return null;
  });
  const product = productToUse ?? (productInInventoryDetail as Product);

  const skuToUpdate = product.dsku;
  const initialSelectedPackaging =
    product.shippingSpecifications?.current?.minimumPackagingType ?? PackagingType.POLY_BAG;
  const initialSelectedPackagingSource =
    product.shippingSpecifications?.current?.source ?? ShippingSpecificationSourceType.SELLER;
  const isChangePackagingButtonDisabled = hasHazmatFragileSpec(
    initialSelectedPackaging,
    initialSelectedPackagingSource
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedPackaging, setSelectedPackaging] = useState<PackagingType>(initialSelectedPackaging);
  const packagingOptions = packagingOptionsArr.map((option) => ({
    label: getLabelContent(option, initialSelectedPackaging, initialSelectedPackagingSource),
    content: packagingDesc[option],
    value: option,
    disabled: isOptionDisabled(option, initialSelectedPackaging, initialSelectedPackagingSource),
  }));
  const userEmail = useSelector((state: RootState) => state.user.email);

  const shippingSpecification: ShippingSpecification = {
    dsku: skuToUpdate,
    source: ShippingSpecificationSourceType.SELLER,
    sourceUser: userEmail,
    minimumPackagingType: selectedPackaging,
  };

  const [, changePackaging] = useAsyncFn(async () => {
    const ctx = { fn: "usePackagingSelect.changePackaging", skuToUpdate };
    log.info({ ...ctx, selectedPackaging }, "changing selected packaging");

    try {
      setIsLoading(true);
      dispatch(addLoader(InventoryLoaderId.DetailPage));
      await productClient.createOrUpdateShippingSpecification(shippingSpecification);
      if (isUpdateFromInboundPage) {
        await dispatch(updateProductCache());
      } else {
        dispatch(getProductDetail(skuToUpdate));
        dispatch(updateEstimatedCosts());
      }
      hideModal();
      toast.success(
        formatMessage({
          id: "InventoryDetail.PackagingSelectModal.changePackagingSuccess",
          defaultMessage: "Packaging successfully changed!",
        }),
        { autoClose: 5000, toastId: "changePackagingSuccess" }
      );
    } catch (err) {
      toast.error(
        formatMessage({
          id: "InventoryDetail.PackagingSelectModal.changePackagingError",
          defaultMessage: "Packaging failed to change. Please try again in a few minutes.",
        }),
        { autoClose: 5000, toastId: "changePackagingError" }
      );
      log.error({ ...ctx, err }, "error changing packaging");
    } finally {
      setIsLoading(false);
      dispatch(clearLoader(InventoryLoaderId.DetailPage));
    }
  }, [skuToUpdate, selectedPackaging, userEmail]);

  const productShippingSpecification = product.shippingSpecifications?.current?.minimumPackagingType;

  useEffect(() => {
    if (productShippingSpecification && productShippingSpecification !== selectedPackaging) {
      setSelectedPackaging(productShippingSpecification);
      dispatch(updateEstimatedCosts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productShippingSpecification]);

  const resetPackagingSelect = () => {
    hideModal();
    setSelectedPackaging(initialSelectedPackaging);
  };

  return {
    initialSelectedPackaging,
    hideModal,
    showModal,
    packagingOptions,
    // If current shipping spec is bubble mailer, Flexport Recommended should be selected
    selectedPackaging: selectedPackaging === PackagingType.BUBBLE_MAILER ? PackagingType.POLY_BAG : selectedPackaging,
    setSelectedPackaging,
    changePackaging,
    resetPackagingSelect,
    isChangePackagingButtonDisabled,
    isLoading,
  };
};
