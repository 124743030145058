import React from "react";
import { Product } from "@deliverr/commons-clients";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { generatePath, Link } from "react-router-dom";
import styled from "@emotion/styled";
import { useTheme } from "emotion-theming";
import { Path } from "paths";
import { Anchor, DefaultTheme, IconV2, Pill, PillColors, Text, ThemeProps } from "common/components/ui";
import { WarningText } from "returns/create/step/ReturnsText";

import {
  IssueType,
  returnsInspectionStatusPillColor,
  returnsDetailIssueMessages,
  inspectionStatusTitles,
  returnsDetailNotesMessages,
} from "./ReturnOrderItemUtils";

import cls from "./ReturnsDetail.less";
import { isEmpty } from "lodash";
import { returnItemInspectionNotes } from "./ReturnOrderItemConstants";

const StyledTr = styled.tr<{}, DefaultTheme>(
  ({ theme }) => `
    box-shadow: 0 1px ${theme.colors.NEUTRAL[60]};
    width: 100%;
  `
);

const StyledDiv = styled.div<{}, DefaultTheme>(
  ({ theme }) => `
    margin-top:  ${theme.spacing.S3};
  `
);

const ImageContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  position: relative;
  width: 100px;
  height: 100px;
  border: ${theme.border.width.B1} ${theme.colors.NEUTRAL[80]} ${theme.border.type};
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  img, video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
);

const ImagesContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.S2};
`
);

interface ReturnOrderItemProps {
  item: {
    areQcItemsPresent: boolean;
    quantity?: number;
    status?: string;
    barcode?: string;
    isUnexpected?: boolean;
    isUnknown?: boolean;
    selectedNotes?: string[];
    images?: string[];
    destroyedAt?: Date;
  } & Product;
}

const ReturnOrderItem = ({ item: { isUnexpected = false, isUnknown = false, ...item } }: ReturnOrderItemProps) => {
  const theme = useTheme<DefaultTheme>();
  const { formatMessage } = useIntl();

  const hasIssue = isUnexpected || isUnknown;
  const itemIssue = isUnknown ? IssueType.UNKNOWN_BARCODE : IssueType.UNEXPECTED_PRODUCT;
  const issueMessage = returnsDetailIssueMessages[itemIssue];

  const { id, defaultMessage: inspectionStatusTitle } = item.status
    ? inspectionStatusTitles[item.status] || inspectionStatusTitles.DEFAULT
    : inspectionStatusTitles.AWAITING;
  const displayProductName = isUnknown ? formatMessage(issueMessage.displayProductName) : item.name;

  const displayProductTitle = item.name ? (
    <Link to={generatePath(Path.inventoryDetail, { dsku: item.dsku })}>{item.name}</Link>
  ) : (
    <Text bold>{displayProductName}</Text>
  );

  const displaySku = item.msku ?? (isUnknown ? item.barcode : item.dsku);
  // At present we only capture a single note but this could change in the future.
  const noteToDisplay =
    item.selectedNotes && item.selectedNotes.length > 0 ? returnsDetailNotesMessages[item.selectedNotes[0]] : null;
  const images = item.images ?? [];
  const shouldShowImages = !isEmpty(images);

  return (
    <StyledTr data-testid="return-item-details">
      <td className={cls.nameCol}>
        {displayProductTitle}
        <Text>{displaySku}</Text>
        {hasIssue && (
          <StyledDiv>
            <IconV2 type="exclamation-triangle" color={theme.colors.YELLOW[500]} />
            <WarningText size="label" as="span">
              <FormattedMessage {...issueMessage.issueMessage} />
            </WarningText>
          </StyledDiv>
        )}
        {noteToDisplay && (
          <StyledDiv>
            <Text bold>
              <FormattedMessage {...returnItemInspectionNotes} />
            </Text>
            <Text>
              <FormattedMessage {...noteToDisplay} />
            </Text>
          </StyledDiv>
        )}
        {shouldShowImages && (
          <StyledDiv>
            <ImagesContainer>
              {images.map((url, currIndex) => (
                <ImageContainer key={`returns-items-image-container-${currIndex}`}>
                  <Anchor href={url} target="_blank" download>
                    <img src={url} alt={"Inspected QC Image"} />
                  </Anchor>
                </ImageContainer>
              ))}
            </ImagesContainer>
          </StyledDiv>
        )}
      </td>
      <td className={cls.colNumeric}>{item.quantity}</td>
      {item.areQcItemsPresent && item.status && (
        <td>
          <Pill color={returnsInspectionStatusPillColor[item.status] || PillColors.YELLOW}>
            <FormattedMessage id={id} defaultMessage={inspectionStatusTitle}></FormattedMessage>
          </Pill>
        </td>
      )}
      {item.areQcItemsPresent && item.destroyedAt && (
        <td>
          <FormattedDate value={item.destroyedAt} year="numeric" month="numeric" day="numeric" />
        </td>
      )}
    </StyledTr>
  );
};

export default ReturnOrderItem;
