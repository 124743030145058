import styled from "@emotion/styled";
import { FeatureName, isFeatureOn, useFeatureOn } from "common/Split";
import ProductChooser, { SelectedProductData } from "common/components/ProductChooser";
import { ManagedStep } from "common/components/StepContainer/ManagedStep";
import { Box, Button, Switch } from "common/components/ui";
import { ElevioArticleId, ElevioLearnMoreButton } from "common/elevio";
import { noop } from "lodash/fp";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TransferCreationTypes } from "transfers/create/transferCreationTypes";
import { transfersShipmentCreateLabels } from "../../transfersShipmentCreate.labels";
import { useTransferPickProductStep } from "./useTransferPickProductStep";
import { BulkUploadModal } from "components/BulkUpload/BulkUploadModal";
import { BULK_UPLOAD_CONTENT_MESSAGES } from "components/BulkUpload/BulkUploadContentMessages";
import { BulkUploadTemplateType } from "components/BulkUpload/BulkUploadTemplateType";
import { isDestinationFBA } from "../common/utils/isDestinationFBA";
import { getWarningForProductFn } from "./validations/getWarningForProductFn";
import {
  ReplenishmentAvailableInventoryErrorNotification,
  useReplenishmentAvailableInventoryErrorNotification,
} from "../common/ReplenishmentAvailableInventoryErrorNotification";
import { useUserScopeMapping } from "common/user/useUserScopes";
import { TOOLTIP_MESSAGES } from "common/utils/tooltipMessages/tooltipMessages";

const ProductSearchContainer = styled.div`
  width: 80%;
`;

const TRANSFERS_PRODUCT_LIMIT = 200;

export const TransferPickProductStep = () => {
  const { formatMessage } = useIntl();
  const {
    isEachPickingEnabled,
    isEachPickingOrder,
    isKitOrderUploadEnabled,
    shouldShowEachesTemplate,
    shouldShowModal,
    setShowModal,
    selectedProducts,
    destinationType,
    distributionChannel,
    addProduct,
    removeProduct,
    isNextDisabled,
    isNextStepLoading,
    isMultiCasePackEnabled,
    isPrepKittingEnabled,
    handleReplenishmentBulkUploadSuccess,
    handleProductImportFailure,
    dskuQuantities,
    switchConfig,
    handleEachPickingToggle,
  } = useTransferPickProductStep();

  const { shouldShowErrorNotification, errorCode, isAvailableWarehouseLoading } =
    useReplenishmentAvailableInventoryErrorNotification(dskuQuantities);

  const isDefaultPoolB2BMerchant = useFeatureOn(FeatureName.DefaultPoolB2BMerchant);

  const shouldShowEachPickButton = isEachPickingEnabled && !isDefaultPoolB2BMerchant;

  const shouldAllowBulkUpload = useFeatureOn(FeatureName.FbaBulkProductCSVUpload) && !isDefaultPoolB2BMerchant;
  const shouldShowEcomDetailsForStorageProducts = destinationType === TransferCreationTypes.Ecom;

  const shouldShowBulkUploadButton =
    shouldAllowBulkUpload && destinationType && ![TransferCreationTypes.Wfs].includes(destinationType);

  const userScopesMapping = useUserScopeMapping();
  const hasReplenishmentWriteAccess = userScopesMapping.REPLENISHMENT?.write;

  const bulkUploadButton = (
    <Button
      secondary
      onClick={() => setShowModal(true)}
      data-testid="product-upload-button"
      disabled={!hasReplenishmentWriteAccess}
      tooltipMessage={!hasReplenishmentWriteAccess ? TOOLTIP_MESSAGES.insufficientAccess : undefined}
    >
      <FormattedMessage {...BULK_UPLOAD_CONTENT_MESSAGES.UPLOAD_FILE} />
    </Button>
  );

  return (
    <ManagedStep
      title={formatMessage(transfersShipmentCreateLabels.steps.product.title)}
      subtitle={
        <>
          {formatMessage(transfersShipmentCreateLabels.steps.product.subtitle)}
          <br />
          {destinationType === TransferCreationTypes.Fba && (
            <FormattedMessage
              {...transfersShipmentCreateLabels.steps.product.subtitleLearnMore}
              values={{
                learnMore: <ElevioLearnMoreButton articleId={ElevioArticleId.TransferCreateAmazonPreRequirements} />,
              }}
            />
          )}
        </>
      }
      isNextDisabled={isNextDisabled || shouldShowErrorNotification}
      isLoading={isAvailableWarehouseLoading || isNextStepLoading}
      data-testid="create-transfer-product-selection-step"
    >
      <ProductSearchContainer>
        {shouldShowErrorNotification && <ReplenishmentAvailableInventoryErrorNotification errorCode={errorCode} />}
        {shouldShowBulkUploadButton && (
          <BulkUploadModal
            show={shouldShowModal}
            closeModal={() => setShowModal(false)}
            casePacked
            isEachesOrder={isEachPickingEnabled && !!isEachPickingOrder}
            isKitOrder={isKitOrderUploadEnabled && !isEachPickingEnabled} // These should be mutually exclusive till we add Kitting in Wholesale order flow
            templateType={
              shouldShowEachesTemplate
                ? BulkUploadTemplateType.REPLENISHMENT_EACHES
                : BulkUploadTemplateType.REPLENISHMENT
            } // Reusing Eaches Template for Kitting as well
            distributionChannel={distributionChannel}
            handleBulkUploadSuccess={handleReplenishmentBulkUploadSuccess}
            handleProductImportFailure={handleProductImportFailure}
            prefixChildren={
              (isEachPickingEnabled || isKitOrderUploadEnabled) && (
                <Switch
                  checked={!!switchConfig.checked}
                  onChange={switchConfig.onChange}
                  label={formatMessage(
                    switchConfig.checked ? switchConfig.labelPath.enabled : switchConfig.labelPath.disabled
                  )}
                />
              )
            }
          />
        )}
        {shouldShowEachPickButton && (
          <Box paddingTop={"S2"} paddingBottom={"S2"}>
            <Switch
              checked={!!isEachPickingOrder}
              onChange={handleEachPickingToggle}
              label={formatMessage(
                isEachPickingOrder
                  ? transfersShipmentCreateLabels.steps.productQuantity.eachPicking.enabled
                  : transfersShipmentCreateLabels.steps.productQuantity.eachPicking.disabled
              )}
            />
          </Box>
        )}
        <TransferProductChooser
          addProduct={addProduct}
          removeProduct={removeProduct}
          selectedProducts={selectedProducts}
          shouldShowBulkUploadButton={shouldShowBulkUploadButton}
          bulkUploadButton={bulkUploadButton}
          isMultiCasePackEnabled={isMultiCasePackEnabled}
          destinationType={destinationType}
          shouldShowEcomDetailsForStorageProducts={shouldShowEcomDetailsForStorageProducts}
          shouldShowKitHint={isPrepKittingEnabled && destinationType === TransferCreationTypes.Ecom}
          isDefaultPoolB2BMerchant={isDefaultPoolB2BMerchant}
        />
      </ProductSearchContainer>
    </ManagedStep>
  );
};

export const TransferProductChooser: React.FC<{
  addProduct: (dsku: string, product: SelectedProductData) => void;
  removeProduct: (dsku: string) => void;
  selectedProducts: SelectedProductData[];
  shouldShowBulkUploadButton: boolean | undefined;
  bulkUploadButton: JSX.Element;
  isMultiCasePackEnabled: boolean;
  destinationType: TransferCreationTypes | undefined;
  shouldShowEcomDetailsForStorageProducts: boolean;
  storageMaximumSelectableCaseQty?: number;
  excludedPackOfSkusForStorage?: string[];
  shouldShowKitHint?: boolean;
  isDefaultPoolB2BMerchant?: boolean;
}> = ({
  addProduct,
  removeProduct,
  selectedProducts,
  shouldShowBulkUploadButton,
  bulkUploadButton,
  isMultiCasePackEnabled,
  destinationType,
  shouldShowEcomDetailsForStorageProducts,
  storageMaximumSelectableCaseQty,
  excludedPackOfSkusForStorage,
  shouldShowKitHint,
  isDefaultPoolB2BMerchant,
}) => {
  return (
    <ProductChooser
      addProduct={addProduct}
      removeProduct={removeProduct}
      selectedProducts={selectedProducts}
      updateQty={noop as any}
      useCasePack={false}
      {...(shouldShowBulkUploadButton && { bulkUploadButton })}
      allowZeroInventorySelection={false}
      shouldShowAvailability
      isReserveStorage={!isDefaultPoolB2BMerchant}
      hideUnitsColumn
      shouldHideCreateProduct
      shouldHideFastTagAlert
      showOnlyPacks={isMultiCasePackEnabled && !isDefaultPoolB2BMerchant}
      shouldHideBatteryColumn
      shouldIncludeBrandedPackaging={isFeatureOn(FeatureName.BrandedPackagingInboundCreation)}
      withWalmartChannelIdOnly={destinationType === TransferCreationTypes.Wfs}
      ShowWarningForProductFn={getWarningForProductFn(destinationType)}
      applyFbaConditions={isDestinationFBA(destinationType)}
      isIntegratedFBA={destinationType === TransferCreationTypes.FbaIntegration}
      productLimit={TRANSFERS_PRODUCT_LIMIT}
      shouldShowEcomDetailsForStorageProducts={shouldShowEcomDetailsForStorageProducts}
      storageMaximumSelectableCaseQty={storageMaximumSelectableCaseQty}
      excludedPackOfSkusForStorage={excludedPackOfSkusForStorage}
      shouldShowKitHint={shouldShowKitHint}
    />
  );
};
