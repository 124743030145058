import { Product } from "@deliverr/commons-clients";
import { ReturnOrderMinimal } from "@deliverr/returns-client";
import { ReturnsCancelState } from "returns/cancel/store/ReturnsCancelStore.types";

export enum ReturnsDetailTypes {
  RETURNS_GET_DETAILS = "RETURNS_GET_DETAILS",
  RETURNS_GET_DETAILS_SUCCESS = "RETURNS_GET_DETAILS_SUCCESS",
  RETURNS_GET_DETAILS_ERROR = "RETURNS_GET_DETAILS_ERROR",
  RESET_RETURN_DETAILS = "RESET_RETURN_DETAILS",
  RETURN_GET_ITEM_DETAILS_SUCCESS = "RETURN_GET_ITEM_DETAILS_SUCCESS",
  RETURN_GET_ITEM_DETAILS_ERROR = "RETURN_GET_ITEM_DETAILS_ERROR",
}

export enum QcStatus {
  PASSED_INSPECTION = "PASSED_INSPECTION",
  SOME_UNITS_WILL_NOT_BE_RESTOCKED = "SOME_UNITS_WILL_NOT_BE_RESTOCKED",
  RESTOCKED = "RESTOCKED",
  NOT_RESTOCKABLE = "NOT_RESTOCKABLE",
  PARTIALLY_RESTOCKED = "PARTIALLY_RESTOCKED",
}

export interface ReturnsDetailState {
  returnItemDetails: { [dsku: string]: Product };
  returnsDetail: ReturnOrderMinimal;
  isLoading: boolean;
  returnsCancel: ReturnsCancelState;
  returnsQcDetails: ReturnsQcDetails;
}

export interface ReturnsQcDetails {
  returnsQcItems: ReturnsQcItem[];
  isQcProcessed: boolean;
  qcStatus?: QcStatus;
}

export interface ReturnsQcItem {
  readonly status?: string;
  readonly dsku: string;
  quantity?: number;
  isUnexpected?: boolean;
  isUnknown?: boolean;
  barcode?: string;
  selectedNotes?: string[];
  images?: string[];
  destroyedAt?: Date;
}
