import React, { FC } from "react";
import Modal from "common/components/Modal";
import styled from "@emotion/styled";
import { ThemeProps, Title, Button, Stack, Box, Text } from "common/components/ui";
import { FormattedMessage } from "common/components/ui/FormattedMessage";
import { FbaPrepDetailsLabels } from "./FbaPrepDetailsLabels";
import { useSetFbaPrepDetailsModal } from "./useSetFbaPrepDetailsModal";
import { ReplenishmentOrderError } from "../../common/replenishment/errors/ReplenishmentOrderError";
import { isEmpty } from "lodash";
import { PrepCategorySelect } from "./FbaPrepCategorySelect";
import { PrepTypesSelect } from "./FbaPrepTypeSelect";
import { getSetPrepDetailsModalIdForSku } from "../commons/FbaV3Constants";

const StyledModal = styled(Modal)<ThemeProps>(
  ({ theme }) => `
  .modal-dialog {
    width: 480px;
  }
  .modal-content {
    display: flex;
    justify-content: center;
    padding: ${theme.spacing.S7};
  }
`
);

const FormTitle = styled(Title)(
  ({ theme }: ThemeProps) => `
  align-self: center;
  padding-top: ${theme.spacing.S3};
  padding-bottom: ${theme.spacing.S3};
`
);

const FormButton = styled(Button)(`
  width: 48%;
`);

const ButtonContainer = styled.div(`
  display: flex;
  justify-content: space-between;
`);

export interface SetFbaPrepDetailsModalProps {
  dsku: string;
  onCloseModal: () => void;
}

export const SetFbaPrepDetailsModal: FC<SetFbaPrepDetailsModalProps> = ({ dsku, onCloseModal }) => {
  const {
    isSubmitDisabled,
    prepCategoryOptions,
    prepTypeOptions,
    selectedPrepCategoryOption,
    selectedPrepTypeOptions,
    isSubmitLoading,
    prepDetailsSubmissionErrors,
    onSubmit,
    onChangePrepCategory,
    onChangePrepTypes,
  } = useSetFbaPrepDetailsModal({
    dsku,
    onCloseModal,
  });

  return (
    <StyledModal id={getSetPrepDetailsModalIdForSku(dsku)} size="md" hasCloseButton={true} onClose={onCloseModal}>
      <Stack>
        <Box width={"100%"} paddingLeft={"S2"} paddingRight={"S2"}>
          <FormTitle as="h2" displayAs="h4">
            <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsFormTitle} />
          </FormTitle>

          <PrepCategorySelect
            prepCategoryOptions={prepCategoryOptions}
            selectedPrepCategoryOption={selectedPrepCategoryOption}
            onChangePrepCategory={onChangePrepCategory}
          />

          <PrepTypesSelect
            prepTypeOptions={prepTypeOptions}
            selectedPrepTypeOptions={selectedPrepTypeOptions}
            onChangePrepTypes={onChangePrepTypes}
          />

          {!isEmpty(prepDetailsSubmissionErrors) ? (
            <Box width={"100%"} paddingTop={"S3"} paddingBottom={"S3"}>
              <Box>
                <Text bold>
                  <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsFormErrorTitle} />
                </Text>
              </Box>

              <ReplenishmentOrderError
                hideTitle
                orderErrors={prepDetailsSubmissionErrors.map((error) => ({
                  errorMessage: error.message,
                  errorCode: error.code
                }))}
              />
            </Box>
          ) : null}

          <ButtonContainer>
            <FormButton secondary onClick={onCloseModal} disabled={isSubmitLoading}>
              <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsFormCancelText} />
            </FormButton>

            <FormButton disabled={isSubmitDisabled} onClick={onSubmit} loading={isSubmitLoading}>
              <FormattedMessage {...FbaPrepDetailsLabels.setPrepDetailsFormSubmitText} />
            </FormButton>
          </ButtonContainer>
        </Box>
      </Stack>
    </StyledModal>
  );
};
