/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import { useCallback, useState } from "react";
import { Product } from "@deliverr/commons-clients";
import { SelectedProductData } from "common/components/ProductChooser";
import { compact, find, isEmpty, map } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getAvailableInventoryInEachStorageWarehouse } from "transfers/create/store/getAvailableInventoryInEachStorageWarehouse";
import {
  transferBulkAddProduct,
  transferCreateAddProduct,
  transferCreateRemoveProduct,
  transferCreateResetProductSelection,
  transferCreateSetIsEachPickingOrderFlag,
} from "transfers/create/actions";
import { selectTransferCreate } from "transfers/create/store/TransferCreateSelectors";
import { extractPacksDims } from "storage/packUtils/extractPacksDims";
import { useFeatureOn, FeatureName } from "common/Split";
import { validateWeightAndDims } from "storage/packUtils/validateWeightAndDims";
import { getDistributionChannel } from "transfers/create/actions/replenishmentOrder/utils/getDistributionChannel";
import { DskuQty } from "common/clients/nonCompliance/BoxLabelIncidentDsku/DskuQty";
import {
  BulkUploadValidationError,
  UploadItem,
  extractBulkUploadValidationErrors,
} from "components/BulkUpload/useBulkUploadModal";
import { BulkUploadError } from "components/BulkUpload/BulkUploadError";
import { DistributionChannel, EntityError, EntityName, ProductCasePackWithATP } from "@deliverr/replenishment-client";
import { addKittedProduct } from "transfers/create/store/addKittedProduct";
import { getProductChooserKittedDSKUDetails } from "common/components/ProductChooser/ProductChooserSelector";
import { loadKittedProducts } from "../common/replenishment/loadKittedProducts";
import { removeKittedProduct } from "transfers/create/store/removeKittedProduct";
import { CreateTransferLoader } from "transfers/create/CreateTransferLoader.types";
import { transfersShipmentCreateLabels } from "transfers/create/transfersShipmentCreate.labels";
import { useLoader } from "common/components/WithLoader/useLoader";
import { useAsync } from "react-use";

export const useTransferPickProductStep = () => {
  const dispatch = useDispatch();
  const { selectedProducts, destinationType, isEachPickingOrder, kitComponentOrderItems } =
    useSelector(selectTransferCreate);
  const kitDskuDetails = useSelector(getProductChooserKittedDSKUDetails);
  const dskuQuantities: DskuQty[] = Object.keys(selectedProducts).map((dsku) => ({ dsku }));
  const [shouldShowModal, setShowModal] = useState<boolean>(false);

  const isMultiCasePackEnabled = useFeatureOn(FeatureName.StorageMultiCasePack);
  const isFbaV3On = useFeatureOn(FeatureName.ReplenishmentFbaV3);

  const isPrepKittingEnabled = useFeatureOn(FeatureName.PrepKittingRSToDTCEnabled);
  const isNextDisabled =
    isEmpty(selectedProducts) || !!find(selectedProducts, (val) => validateWeightAndDims(extractPacksDims(val)));
  const distributionChannel = getDistributionChannel(destinationType, isFbaV3On);
  const isWholesaleOrderEachPickingOn = useFeatureOn(FeatureName.WholesaleOrderEachPicking);
  const isEachPickingEnabled = distributionChannel === DistributionChannel.WHOLESALE && isWholesaleOrderEachPickingOn;
  const isNextStepLoading = useLoader(CreateTransferLoader.NextStepLoader);
  const isKitOrderUploadEnabled =
    isPrepKittingEnabled && distributionChannel === DistributionChannel.ECOM_VIA_CROSSDOCK;
  const [isKitOrderEnabled, setIsKitOrderEnabled] = useState(isKitOrderUploadEnabled);

  const addProduct = (dsku: string, product: SelectedProductData) => {
    if (isPrepKittingEnabled && product.isKit && product.caseQty === 1) {
      // We will allow kitting only for the products with case pack qty 1
      dispatch(addKittedProduct(dskuQuantities, product, isMultiCasePackEnabled));
      return;
    }
    const dskuList = [...dskuQuantities, { dsku }];
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    dispatch(transferCreateAddProduct(dsku, product));
  };

  const removeProduct = (dsku: string) => {
    if (isPrepKittingEnabled && kitDskuDetails?.[dsku]) {
      dispatch(removeKittedProduct(dsku, dskuQuantities, isMultiCasePackEnabled));
      return;
    }
    const dskuList = dskuQuantities.filter((dskuQuantity) => dskuQuantity.dsku !== dsku);
    dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    dispatch(transferCreateRemoveProduct(dsku));
  };

  useAsync(async () => {
    if (kitComponentOrderItems) {
      const dskus = kitComponentOrderItems?.map((item) => ({ dsku: item.dsku, qty: item.totalUnits }));
      if (!isEmpty(dskus)) {
        await dispatch(getAvailableInventoryInEachStorageWarehouse(dskus, isMultiCasePackEnabled, true));
      }
    }
  }, [dispatch, isMultiCasePackEnabled, kitComponentOrderItems]);

  const handleKitReplenishmentBulkUploadSuccess = useCallback(
    async (inputProducts) => {
      // Type is different as per bulk upload usecases. Kitting details has just enough that is required.
      await dispatch(loadKittedProducts(inputProducts, false, isMultiCasePackEnabled));
    },
    [dispatch, isMultiCasePackEnabled]
  );

  const handleReplenishmentBulkUploadSuccess = useCallback(
    async (inputProducts: (ProductCasePackWithATP & UploadItem)[]) => {
      if (!inputProducts) {
        return;
      }

      dispatch(transferCreateResetProductSelection());

      if (isKitOrderUploadEnabled && isKitOrderEnabled) {
        await handleKitReplenishmentBulkUploadSuccess(inputProducts);
        return;
      }

      let skuProductMap = {};

      const productsWithoutInventory: UploadItem[] = [];

      for (const product of inputProducts) {
        skuProductMap = {
          ...skuProductMap,
          [product.dsku]: {
            ...product,
            storageOnHandQty: product.onHandQty,
            storageUnfilledOrderQty: product.unfilledOrderQty,
            storageUnavailableQty: product.unavailableQty,
            objectID: product.dsku,
          },
        };
      }

      if (!isEmpty(productsWithoutInventory)) {
        throw new EntityError({
          code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
          entity: EntityName.REPORT,
          message: "Inventory unavailable for selected products in CSV",
          errors: productsWithoutInventory.map(
            (product) =>
              new EntityError({
                code: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
                entity: EntityName.ORDER_ITEM,
                entityId: product.msku,
                payload: product,
              })
          ),
        });
      }

      const dskuList = Object.values(skuProductMap).map((product: Product) => ({ dsku: product?.dsku }));
      dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
      dispatch(transferBulkAddProduct(skuProductMap));
    },
    [
      dispatch,
      handleKitReplenishmentBulkUploadSuccess,
      isKitOrderEnabled,
      isKitOrderUploadEnabled,
      isMultiCasePackEnabled,
    ]
  );

  const handleProductImportFailure = (err: any, setValidationErrors: (errors: BulkUploadValidationError[]) => void) => {
    if (err instanceof EntityError && err.code === BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE) {
      setValidationErrors(
        extractBulkUploadValidationErrors({
          [BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE]: {
            cells: compact(map(err.errors, ({ entityId }) => entityId as string)),
            isWholeSheetError: false,
            error: BulkUploadError.PRODUCT_INVENTORY_UNAVAILABLE,
          },
        } as Record<BulkUploadError, BulkUploadValidationError>)
      );
    } else {
      throw err;
    }
  };

  const handleEachPickingToggle = async () => {
    await dispatch(transferCreateSetIsEachPickingOrderFlag(!isEachPickingOrder));
    const dskuList = dskuQuantities.map((dskuQuantity) => ({ dsku: dskuQuantity.dsku }));

    if (!isEmpty(dskuList)) {
      dispatch(getAvailableInventoryInEachStorageWarehouse(dskuList, isMultiCasePackEnabled));
    }
  };

  const handleKitOrderToggle = () => {
    setIsKitOrderEnabled(!isKitOrderEnabled);
  };

  const switchConfig = isEachPickingEnabled
    ? {
        checked: !!isEachPickingEnabled,
        onChange: handleEachPickingToggle,
        labelPath: transfersShipmentCreateLabels.steps.product.eachPicking,
      }
    : {
        checked: !!isKitOrderEnabled,
        onChange: handleKitOrderToggle,
        labelPath: transfersShipmentCreateLabels.steps.product.kitOrderEnabled,
      };

  const shouldShowEachesTemplate = !!(isEachPickingEnabled && isEachPickingOrder) || isKitOrderEnabled;

  return {
    isEachPickingEnabled,
    isEachPickingOrder,
    isKitOrderUploadEnabled,
    shouldShowEachesTemplate,
    shouldShowModal,
    setShowModal,
    addProduct,
    selectedProducts: Object.values(selectedProducts),
    removeProduct,
    isNextDisabled,
    isNextStepLoading,
    dskuQuantities,
    isMultiCasePackEnabled,
    isPrepKittingEnabled,
    destinationType,
    distributionChannel,
    handleReplenishmentBulkUploadSuccess,
    handleProductImportFailure,
    switchConfig,
    handleEachPickingToggle,
  };
};
